import './logo.scss'
import LogoW from '../../../assets/images/w-logo.png'
import { useEffect, useRef } from 'react'
import { gsap } from 'gsap'

const Logo = () => {
  const bgRef = useRef()
  const outlineLogoRef = useRef()
  const solidLogoRef = useRef()

  useEffect(() => {
    gsap.timeline().to(bgRef.current, {
      duration: 1,
      opacity: 1,
    })

    gsap.fromTo(
      solidLogoRef.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: 4,
        duration: 4,
      }
    )
  }, [])

  return (
    <div className="logo-container" ref={bgRef}>
      <img ref={solidLogoRef} className="solid-logo" src={LogoW} alt="W" />
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="300.000000pt"
        height="155.000000pt"
        viewBox="0 0 300.000000 155.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          className="svg-container"
          transform="translate(0.000000,155.000000) scale(0.100000,-0.100000)"
          fill="none"
          ref={outlineLogoRef}
        >
          <path d="M14 1523 c3 -10 131 -353 284 -763 l277 -745 360 -5 c198 -3 361 -5 362 -5 1 1 -125 346 -280 768 l-282 767 -363 0 c-342 0 -363 -1 -358 -17z m693 -25 c6 -7 38 -89 73 -183 34 -93 73 -197 85 -230 13 -33 74 -197 135 -365 61 -168 127 -346 146 -396 89 -240 102 -283 90 -293 -6 -5 -134 -9 -314 -8 -344 2 -317 -5 -348 91 -9 28 -31 89 -49 136 -18 47 -50 130 -70 185 -61 165 -163 440 -227 610 -56 150 -70 188 -121 325 -11 30 -26 69 -33 87 -8 17 -14 36 -14 42 0 17 634 15 647 -1z" />
          <path d="M1125 1528 c-2 -7 -34 -94 -71 -193 -36 -99 -68 -187 -70 -195 -3 -8 40 -134 94 -280 54 -146 136 -367 182 -492 46 -125 86 -225 89 -223 3 2 38 91 76 199 39 108 73 196 76 196 3 0 13 -24 22 -52 9 -29 52 -149 95 -266 l79 -213 361 -2 c199 -1 362 1 362 4 0 3 -30 86 -66 185 -198 540 -476 1293 -486 1317 l-10 27 -365 0 c-282 0 -365 -3 -368 -12z m755 -148 c216 -572 491 -1333 486 -1345 -5 -13 -47 -15 -298 -14 -161 1 -304 4 -318 8 -26 7 -42 45 -169 391 -67 185 -98 182 -170 -18 -50 -138 -56 -151 -70 -137 -5 6 -46 109 -91 230 -44 121 -87 236 -95 255 -7 19 -41 110 -75 202 l-62 168 20 62 c22 71 82 234 105 291 l16 37 337 -2 337 -3 47 -125z" />
          <path d="M2256 1498 c-9 -24 -44 -122 -79 -218 l-62 -175 182 -490 c166 -447 182 -488 192 -462 6 16 11 30 11 32 0 2 110 304 245 670 135 366 245 670 245 676 0 5 -142 9 -359 9 l-359 0 -16 -42z m684 -1 c0 -7 -50 -148 -111 -313 -61 -164 -113 -308 -116 -319 -3 -11 -9 -24 -13 -30 -4 -5 -10 -23 -14 -40 -4 -16 -18 -57 -31 -90 -13 -33 -54 -141 -90 -240 -74 -200 -84 -221 -94 -209 -18 22 -311 821 -311 849 0 19 80 253 129 378 l11 27 320 0 c259 0 320 -3 320 -13z" />
        </g>
      </svg>
    </div>
  )
}

export default Logo
